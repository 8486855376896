<template>
  <base-section
    id="departments"
  >
    <base-section-heading
      color="grey lighten-2"
      :title="translations.contactPlacesTitle"
    >
      <div v-html="documentToHtmlString(translations.contactPlacesDescription)" />
    </base-section-heading>
    <v-responsive
      class="mx-auto secondary"
    >
      <v-container
        fluid
      >
        <v-row class="justify-center">
          <v-col
            v-for="place in places"
            :key="place.title"
            cols="12"
            sm="4"
            md="3"
          >
            <base-department-card
              align="center"
              dark
              icon="mdi-map-marker-outline"
              :title="place.title"
              :scroll-to="place.scrollTo"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  import BaseDepartmentCard from '../../components/base/DepartmentCard'
  import { STORE_NAMESPACE_CONTACT } from '@/views/contact/Index.vue'
  import { mapState } from 'vuex'
  import { documentToHtmlString } from '@contentful/rich-text-html-renderer'

  export default {
    name: 'SectionContactPlaces',
    components: { BaseDepartmentCard },
    computed: {
      ...mapState(STORE_NAMESPACE_CONTACT, [
        'translations',
      ]),
      places () {
        return [
          {
            title: this.translations?.placeSecondCity,
            scrollTo: 'placeSecondCity',
          },
          {
            title: this.translations?.placeThirdCity,
            scrollTo: 'placeThirdCity',
          },
          {
            title: this.translations?.placeFourthCity,
            scrollTo: 'placeFourthCity',
          },
          {
            title: this.translations?.placeMainCity,
            scrollTo: 'placeFifthCity',
          },

        ]
      },

    },
    methods: {
      documentToHtmlString,
    },

  }
</script>
