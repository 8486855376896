<script>
  // Extensions
  import View from '@/views/View'

  // Mixins
  import LoadSections from '@/mixins/load-sections'
  import FetchPage from '@/mixins/fetchPage'
  const CONTACT = '5oU7JvxkfoQOVTM3bhDjTN'
  export const STORE_NAMESPACE_CONTACT = 'page/contact'
  export default {
    name: 'Contact',

    metaInfo: { title: 'Kontakt' },

    extends: View,

    mixins: [
      LoadSections([
        'sub-hero',
        // 'contact-description',
        'contact-features',
        'contact-place-info-main',
        'contact-places',
        // 'contact-departments-info',
        'contact-place-info-second',
        'contact-place-info-third',
        'contact-place-info-fourth',
        'contact-place-info-fifth',
        'trusted-us',
        'footer',
      ]),
      FetchPage(CONTACT, STORE_NAMESPACE_CONTACT),
    ],
    props: {
      id: {
        type: String,
        default: 'about',
      },
    },
  }
</script>
